import { StyledEngineProvider } from '@mui/material/styles'
import { Route, Router } from 'some-utils/npm/react/router'
import { routes } from 'project'
import { useUser } from 'state/user'
import { useResponsive } from 'state/responsive'
import { go } from 'state/routing'
import { useAutoBlurButton } from 'utils'
import { Sign } from 'view/Sign'
import { SurveyList } from 'view/SurveyList'
import { SurveyPage } from 'view/SurveyPage'
import { ResetPassword } from 'view/ResetPassword'
import { Header } from 'view/Header'
import { svg } from 'view/svg'
import packageJson from '../package.json'
import './App.css'

const Home = () => {  
  return (
    <div className='Home absolute-fill flex column center'>
      <SurveyList />
    </div>
  )
}

const HomeAlt = () => {
  return (
    <div className='fill flex column center'>
      <button 
        className='link flex column center' style={{ width: '240px'}}
        onClick={go(routes.SIGN)}
      >
        <svg.SurveyToolIcon />
        Sign in
      </button>
    </div>
  )
}

export const App = () => {

  const { authenticated } = useUser()
  useResponsive()
  useAutoBlurButton()

  return (
    <Router>
      <StyledEngineProvider injectFirst>

        <div className='App vh-100 flex column' data-version={packageJson.version}>

          <Route path='*' excludePath={routes.SIGN}>
            <Header />
          </Route>

          <main>
            <Route path={routes.HOME} exact>
              {authenticated ? (
                <Home />
              ) : (
                <HomeAlt />
              )}
            </Route>

            <Route path={routes.SIGN}>
              <Sign />
            </Route>

            <Route path={routes.SURVEY}>
              <SurveyPage />
            </Route>

            <Route path={routes.RESET_PASSWORD}>
              <ResetPassword />
            </Route>
          </main>

        </div>
        
      </StyledEngineProvider>
    </Router>
  )
}
