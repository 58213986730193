import { useState } from 'react'
import { Snackbar } from '@mui/material'
import { usePromise } from 'some-utils/npm/react'
import { getHash, getSearch, setLocation } from 'some-utils/router'
import { postJson } from 'utils'
import { sign } from 'state/user'
import { ReactComponent as VisibleOnSvg } from 'assets/svg/visible-on.svg'
import { ReactComponent as VisibleOffSvg } from 'assets/svg/visible-off.svg'
import './ResetPassword.css'

export const ResetPassword = () => {
  const email = getSearch()
  const resetPasswordHash = getHash()
  const resetPasswordHashFromRemote = usePromise(() => postJson<string>('/api/user/reset-password-hash', { email }))
  const [state, setState] = useState({ password: '', passwordVisible: false })
  const [snack, setSnack] = useState<null | string>(null)

  if (!resetPasswordHashFromRemote) {
    return null
  }

  if (resetPasswordHash !== resetPasswordHashFromRemote.payload) {
    return (
      <div className='ResetPassword fill flex column center'>
        <p>Invalid request!</p>
      </div>
    )
  }

  return (
    <div className='ResetPassword fill flex column center'>
      <div className='Inner flex column gutter-8'>
        
        <p>{email}</p>

        <div className='flex row'>
          <input 
            className='expand border-bottom' 
            type={state.passwordVisible ? 'text' : 'password'}
            placeholder='New password'
            value={state.password}
            onInput={event => setState({ ...state, password: (event.target as HTMLInputElement).value})}
          />
          <div
            className='button flex row center padding-4'
            onClick={() => setState({ ...state, passwordVisible: !state.passwordVisible })}>
            {state.passwordVisible ? <VisibleOnSvg /> : <VisibleOffSvg />}
          </div>
        </div>

        <button onClick={async () => {
          const ok = await sign(email, state.password, resetPasswordHash)
          if (ok) {
            setLocation({
              pathname: '/',
              hash: '',
              search: '',
            })
          }
          else {
            setSnack('Something went wrong.')
          }
        }}>
          Change password
        </button>
      </div>

      <Snackbar 
        open={snack !== null}
        message={snack}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={() => setSnack(null)}
      />
    </div>
  )
}
