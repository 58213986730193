import { DbItem, Survey } from 'project'
import { useState } from 'react'
import { safeClassName } from 'some-utils/npm/react'
import { fetchSurveys, userPost } from 'state/user'
import { defaultNameLocationState, NameLocation, NameLocationState } from '../NameLocation'
import { svg } from '../svg'

type Props = {
  survey: DbItem<Survey>
  onClose: () => void
}

export const EditSurvey = ({
  survey,
  onClose,
}: Props) => {

  const [state, setState] = useState<NameLocationState>({
    ...defaultNameLocationState,
    name: survey.props.name,
    location: survey.props.location,
  })

  const {
    name,
    location,
    valid,
  } = state

  return (
    <div className='EditSurvey ModalContent flex column center gutter-8'>

      <h1 className='flex row center pre'>
        <svg.Edit />
        <span className='space-8' />
        Edit Survey
      </h1>

      <div className='space-1em' />

      <NameLocation 
        state={state}
        setState={setState}/>

      <div className='space-1em' />
      
      <button
        className={safeClassName('button', { disabled: valid === false })}
        onClick={async () => {
          if (valid) {
            survey.props.name = state.name
            survey.props.location = state.location
            const { ok, message } = await userPost<DbItem<Survey>>('/api/survey/update', { survey })
            if (ok) {
              await fetchSurveys()
              onClose()
            }
            else {
              console.log(message)
            }
          }
        }}>
       Update
      </button>

    </div>
  )
}
