import { useState } from 'react'
import { safeClassName } from 'some-utils/npm/react'
import { ReactComponent as VisibleOnSvg } from 'assets/svg/visible-on.svg'
import { ReactComponent as VisibleOffSvg } from 'assets/svg/visible-off.svg'
import { ReactComponent as TrashSvg } from 'assets/svg/trash.svg'
import { isValidEmail, isValidPassword, useLocalStorage } from 'utils'

export const EmailPassword = ({
  onEmailChange = (email: string, validEmail: boolean) => {},
  onSubmit = (email: string, password: string) => {}, 
  submitLabel = 'submit',
  invalidPassword = false,
}) => {

  const [store, setStore] = useLocalStorage(`sign`, {
    email: '',
    password: '',
    passwordVisible: false,
  })

  const [state, setState] = useState(() => {
    const { email, password } = store
    const validEmail = isValidEmail(email)
    const validPassword = isValidPassword(password)
    const valid = validEmail && validPassword
    return { valid, validEmail, validPassword, email, password }
  })

  const compute = ({ email = state.email, password = state.password } = {}) => {
    const validEmail = isValidEmail(email)
    const validPassword = isValidPassword(password)
    const valid = validEmail && validPassword
    return { valid, validEmail, validPassword, email, password }
  }

  return (
    <>

      <div className='flex row gutter-4'>
        <input
          className='border-bottom input'
          style={{ flex: 1 }}
          name='email'
          type='email'
          placeholder='email'
          value={state.email}
          onBlur={() => onEmailChange(state.email, state.validEmail)}
          onInput={e => {
            const { email, validEmail, ...props } = compute({ email: (e.target as HTMLInputElement).value })
            onEmailChange(email, validEmail)
            setState({ ...props, email, validEmail })
          }} />
        {!!store.email && (
          <div 
            className='button flex row center padding-4'
            onClick={() => {
              setStore({ email: '' })
              setState({ ...state, email: '' })
            }}>
            <TrashSvg />
          </div>
        )}
      </div>

      <div className='flex row gutter-4'>
        <input
          className={safeClassName('border-bottom input', invalidPassword && 'invalid')}
          disabled={state.validEmail === false}
          style={{ flex: 1 }}
          name='password'
          type={store.passwordVisible ? 'text' : 'password'}
          placeholder='password'
          value={state.password}
          onInput={e => {
            const newState = compute({ password: (e.target as HTMLInputElement).value })
            setState(newState)
          }} />
        <div
          className='button flex row center padding-4'
          onClick={() => setStore({ passwordVisible: !store.passwordVisible })}>
          {store.passwordVisible ? <VisibleOnSvg /> : <VisibleOffSvg />}
        </div>
        {!!store.password && (
          <div 
            className='button flex row center padding-4'
            onClick={() => {
              setStore({ password: '' })
              setState({ ...state, password: '' })
            }}>
            <TrashSvg />
          </div>
        )}
      </div>

      <button
        onClick={() => {
          const { valid, email, password } = state
          if (valid) {
            setStore({ email, password }, { delay: 500 })
            onSubmit(email, password)
          }
        }}
        className={safeClassName('button', { disabled: !state.valid })}
      >
        {submitLabel}
      </button>
    </>
  )
}
