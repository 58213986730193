import { ApiMessage } from 'project'

export const fetchText = async (url: string) => {
  const response = await fetch(url)
  return response.text()
}

export const postJson = async <T = undefined>(url: string, body?: any) => {
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({ ...body }),
    headers: { 'Content-Type': 'application/json' },
  })
  const json = await response.json()
  return json as ApiMessage<T>
}

export const downloadStringAsFile = (filename: string, content: string) => {
  const element = document.createElement('a')
  element.setAttribute('href', 'data:text/plaincharset=utf-8,' + encodeURIComponent(content))
  element.setAttribute('download', filename)

  element.style.display = 'none'
  document.body.appendChild(element)

  element.click()

  document.body.removeChild(element)
}
