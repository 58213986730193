import { DbItem, Survey as SurveyType } from 'project'
import { safeClassName } from 'some-utils/npm/react'
import './RatioBar.css'

export const RatioBar = ({
  survey, 
  positiveClick, 
  negativeClick,
}: {
  survey: DbItem<SurveyType>
  positiveClick?: () => void
  negativeClick?: () => void
}) => {

  const {
    status,
    positiveVotesCount: positive = 0,
    negativeVotesCount: negative = 0,
  } = survey.props
  
  const total = positive + negative

  return (
    <div className={`RatioBar ${status} absolute-fill flex row`}>
      {total > 0 && (
        <>
          {positive > 0 && (
            <div
              className={safeClassName('Bar Positive', positiveClick && 'button')}
              style={{ flex: positive }}
              onClick={positiveClick}
            />
          )}
          {(positive > 0 && negative > 0) && (
            <div
              className='Separator' 
              style={{ flex: '0 0 1px' }}
            />
          )}
          {negative > 0 && (
            <div
              className={safeClassName('Bar Negative', negativeClick && 'button')}
              style={{ flex: negative }}
              onClick={negativeClick}
            />
          )}
        </>
      )}
    </div>
  )
}
