import { ReactComponent as Play } from 'assets/svg/play.svg'
import { ReactComponent as Pause } from 'assets/svg/pause.svg'
import { ReactComponent as ArrowCycle } from 'assets/svg/arrow-cycle.svg'
import { ReactComponent as Previous } from 'assets/svg/previous.svg'
import { ReactComponent as DropdownArrow } from 'assets/svg/dropdown-arrow.svg'
import { ReactComponent as Lock } from 'assets/svg/lock.svg'
import { ReactComponent as Unlock } from 'assets/svg/unlock.svg'
import { ReactComponent as PlusButton } from 'assets/svg/plus-button.svg'
import { ReactComponent as Trash } from 'assets/svg/trash.svg'
import { ReactComponent as Location } from 'assets/svg/location.svg'
import { ReactComponent as Edit } from 'assets/svg/edit.svg'
import { ReactComponent as SurveyToolIcon } from 'assets/SurveyTool-Icon.svg'

export const svg = {
  Play,
  Pause,
  ArrowCycle,
  Previous,
  DropdownArrow,
  Lock,
  Unlock,
  PlusButton,
  Trash,
  Location,
  Edit,
  SurveyToolIcon,
}
