import yaml from 'yaml'
import papaparse from 'papaparse'
import { DbItem, Survey } from 'project'
import { downloadStringAsFile } from './utils'

export const transformData = (survey: DbItem<Survey>) => {

  const {
    user,
    name,
    location,
    positiveVotesCount: positiveVotes,
    negativeVotesCount: negativeVotes,
    ...props
  } = survey.props

  const data = {
    name,
    location,
    positiveVotes,
    negativeVotes,
    totalVotes: positiveVotes + negativeVotes,
    ...props
  }

  return data
}

export const downloadData = (survey: DbItem<Survey> | DbItem<Survey>[], dataType: string) => {

  const data = Array.isArray(survey) 
    ? survey.map(s => transformData(s))
    : transformData(survey)

  const name = Array.isArray(survey)
    ? 'surveys'
    : survey.props.name

  if (dataType === 'yaml') {
    const str = yaml.stringify(data)
    downloadStringAsFile(`${name}.yaml`, str)
  }

  else if (dataType === 'json') {
    const str = JSON.stringify(data)
    downloadStringAsFile(`${name}.json`, str)
  }

  else if (dataType === 'csv') {
    const csvData = Array.isArray(data) ? data : [data]
    const str = papaparse.unparse(csvData, { header: true })
    downloadStringAsFile(`${name}.csv`, str)
  }

  else {
    throw new Error(`Invalid dataType: "${dataType}"`)
  }
}