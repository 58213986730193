import bcrypt from 'bcryptjs'
import { DbItem, routes, Survey, Token } from 'project'
import { useForceUpdate, useObservable } from 'some-utils/npm/react'
import { Observable, ObservableObject } from 'some-utils/observables'
import { postJson } from 'utils'
import { go } from './routing'

export type SignStatus = 'start' | 'out' | 'fetching' | 'authenticated'
interface InnerUser {
  signStatus: SignStatus
  email: string
  token: string
}
export interface User extends InnerUser {
  authenticated: boolean
  surveys: DbItem<Survey>[]
}

export const user = new ObservableObject<InnerUser>({
  signStatus: 'fetching',
  email: '',
  token: '',
})

export const surveys = new Observable<DbItem<Survey>[]>([])

// DEBUG
// user.onChange(() => console.log('user updated', user.value))
// surveys.onChange(() => console.log('surveys updated', surveys.value))

export const fetchSurveys = async () => {
  const { email, token } = user.value
  const { ok, payload, message } = await postJson<DbItem<Survey>[]>('/api/survey/all', { email, token })
  if (ok) {
    surveys.setValue(payload)
  }
  else {
    console.log(message)
  }
}

user.onPropChange('signStatus', (status: SignStatus) => {
  if (status === 'authenticated') {
    fetchSurveys()
  }
})

const fetchUser = async () => {

  const email = localStorage.getItem('email')
  const token = localStorage.getItem('token')

  if (email && token) {
    user.updateValue({ signStatus: 'fetching', email })
    const { ok, message, payload } = await postJson<Token>(`/api/user/token-sign`, { email, token })
    if (ok) {
      const token = payload.value
      localStorage.setItem('token', token)
      user.updateValue({ signStatus: 'authenticated', email, token })
      return
    }
    console.log(message)
  }

  user.updateValue({ signStatus: 'out', email: '' })
}

let userInitialized = false
export const useUser = (): User => {
  if (userInitialized === false) {
    userInitialized = true
    fetchUser()
  }
  const forceUpdate = useForceUpdate({ waitNextFrame: false })
  user.onChange(forceUpdate)
  surveys.onChange(forceUpdate)
  return {
    ...user.value,
    authenticated: user.value.signStatus === 'authenticated',
    surveys: surveys.value,
  }
}

export const useSurvey = (id64: string) => {
  useUser()
  return useObservable(surveys).find(survey => survey.id64 === id64) ?? null
}

export const updateSurvey = async (survey: DbItem<Survey>) => {
  const { email, token } = user.value
  const { ok, message, payload } = await postJson<DbItem<Survey>>('/api/survey/update', { email, token, survey })
  if (ok) {
    surveys.setValue(surveys.value.map(current => {
      return current.id === payload.id ? payload : current
    }))
  }
  else {
    console.error(message)
  }
}

export const sign = async (email: string, password: string, resetPasswordHash?: string) => {
  const salt = await (await fetch(`/api/user/email-salt/${email}`)).text()
  const hash = await bcrypt.hash(password, salt)
  const response = await postJson<Token>(`/api/user/sign`, { email, hash, resetPasswordHash })
  const { ok, payload } = response
  if (ok) {
    const { value: token } = payload
    localStorage.setItem('email', email)
    localStorage.setItem('token', token)
    user.updateValue({ signStatus: 'authenticated', email, token: token })
  }
  return ok
}

export const logout = () => {
  localStorage.setItem('token', '')
  user.updateValue({ signStatus: 'out', email: '' })
}

export const userPost = async <T>(url: string, body?: any) => {
  const { email, token } = user.value
  const response = await postJson<T>(url, { ...body, email, token })
  return response
}

export const useHomeRedirectWhenOut = () => {
  const { signStatus } = useUser()
  if (signStatus === 'out') {
    go(routes.HOME)()
  }
}
