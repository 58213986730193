import { useState } from 'react'
import { DateTime } from 'luxon'
import { handleKeyboard } from 'some-utils/dom'
import { safeClassName, useComplexEffects } from 'some-utils/npm/react'
import { getPathname } from 'some-utils/router'
import { useResponsive } from 'state/responsive'
import { DbItem, routes, Survey } from 'project'
import { useSurvey, updateSurvey, useHomeRedirectWhenOut } from 'state/user'
import { go } from 'state/routing'
import { Dialog } from '@mui/material'
import { downloadData } from 'utils/dowload-data'
import { RatioBar } from './RatioBar'
import { svg } from './svg'
import { DownloadButton } from './DownloadButton'
import { CloseSurvey, DeleteSurvey, EditSurvey } from './modal'
import './SurveyPage.css'

const StatusButton = ({
  survey,
}: { survey: DbItem<Survey> }) => {

  const { status } = survey.props

  const label = {
    live: 'Survey in progress...',
    paused: 'Survey suspended.',
    close: 'Survey closed.',
  }[status]

  const Icon = {
    live: svg.ArrowCycle,
    paused: svg.Pause,
    close: svg.Lock,
  }[status]

  const onClick = {
    live: () => {
      survey.props.status = 'paused'
      updateSurvey(survey)
    },
    paused: () => {
      survey.props.status = 'live'
      updateSurvey(survey)
    },
    close: () => {},
  }[status]

  const button = status !== 'close'

  return (
    <div
      className={safeClassName('StatusButton', status, { button })}
      onClick={onClick}
    >
      <div className='Blink absolute-fill'/>
      <div className='Border absolute-fill'/>
      <div className='Content absolute-fill flex row center gutter-16'>
        <span className='Icon flex row center'>
          <Icon />
        </span>
        <span>
          {label}
        </span>
      </div>
    </div>
  )
}

const SurveyComponent = ({ survey }: { survey: DbItem<Survey> }) => {
  const responsive = useResponsive()

  type ModalType = 'close' | 'delete' | 'edit'
  const [modal, setModal] = useState<null | ModalType>(null)
  const closeModal = () => setModal(null)
  const openModal = (type: ModalType) => () => setModal(type)

  useComplexEffects(function* () {
    yield handleKeyboard({
      onDown: [
        [
          'KeyY', info => {
            const { altKey, shiftKey } = info.event
            if (altKey && shiftKey) {
              survey.props.positiveVotesCount++
              updateSurvey(survey)
            }
          },
        ],
        [
          'KeyN', info => {
            const { altKey, shiftKey } = info.event
            if (altKey && shiftKey) {
              survey.props.negativeVotesCount++
              updateSurvey(survey)
            }
          },
        ],
      ]
    })
  }, [survey.id])

  const positiveClick = () => {
    survey.props.positiveVotesCount++
    updateSurvey(survey)
  }

  const negativeClick = () => {
    survey.props.negativeVotesCount++
    updateSurvey(survey)
  }

  const { 
    positiveVotesCount: positive, 
    negativeVotesCount: negative,
    status,
  } = survey.props
  const total = positive + negative
  const disabled = status === 'close'

  const mobile = responsive.includes('mobile')
  const gutter = mobile ? ' gutter-8' : ' gutter-16'

  return (
    <div className={`fill padding-32 flex column ${gutter}`}>
      
      <h1 className='flex row align-center gutter-8 button blank'>
        <span className='Name'>{survey.props.name}</span>
        <span className='Id'> #{survey.id64}</span>
        <button 
          className='EditButton button flex row center' 
          onClick={openModal('edit')}>
          <svg.Edit />
        </button>
      </h1>

      <h2 className='flex row'>
        <div style={{ transform: 'translate(-.15em, .1em)' }}>
          <svg.Location />
        </div>
        {survey.props.location}
      </h2>

      <div>
        {DateTime.fromISO(survey.props.created).toLocaleString()}
      </div>

      <div className='space-2em'/>

      <div className='Info small flex row gutter-8 align-center text-center'>
        <div>
          {!mobile ? `total votes: ${total}` : `total: ${total}`}
        </div>
        <span>/</span>
        <div>
          {!mobile ? `positive votes: ${positive}` : `positive: ${positive}`}
        </div>
        <span>/</span>
        <div>
          {!mobile ? `negative votes: ${negative}` : `negative: ${negative}`}
        </div>
      </div>

      <div className='Status'>
        <StatusButton survey={survey} />
      </div>

      <div className='Progress'>

        <RatioBar survey={survey} />

        <div className='Counter absolute-fill flex row no-click'>
          <div className='flex row align-center gutter-8'>
            <span>{positive}</span>
            <span>👍</span>
          </div>
          <div className='expand'/>
          <div>Total [ {total} ]</div>
          <div className='expand'/>
          <div className='flex row align-center gutter-8'>
            <span>👎</span>
            <span>{negative}</span>
          </div>
        </div>

      </div>

      {status !== 'close' && (
        <div className={`VoteButtons flex row ${gutter}`}>
          <button
            className={safeClassName('expand', { disabled: status !== 'live' })}
            onClick={positiveClick}
          >
            👍
          </button>
          <button
            className={safeClassName('expand', { disabled: status !== 'live' })}
            onClick={negativeClick}
          >
            👎
          </button>
        </div>
      )}

      <div className={`ActionButtons stretch flex column ${gutter}`}>

        <DownloadButton 
          onClick={type => downloadData(survey, type)} />

        {status !== 'close' && (
          <button
            className={safeClassName('padding-1em', { disabled })}
            onClick={openModal('close')}>
            Close the survey.
          </button>
        )}

        <button
          className='padding-1em'
          onClick={openModal('delete')}>
          Delete the survey.
        </button>
        
      </div>

      <Dialog
        open={modal === 'edit'}
        onClose={closeModal}
      >
        <EditSurvey
          onClose={closeModal}
          survey={survey}/>
      </Dialog>

      <Dialog
        open={modal === 'close'}
        onClose={closeModal}
      >
        <CloseSurvey
          onClose={closeModal}
          survey={survey}/>
      </Dialog>

      <Dialog
        open={modal === 'delete'}
        onClose={closeModal}
      >
        <DeleteSurvey
          onClose={closeModal}
          onDelete={go(routes.HOME)}
          survey={survey}/>
      </Dialog>
    </div>
  )
}

export const SurveyPage = () => {

  const id64 = getPathname().split('/').pop()!
  const survey = useSurvey(id64)

  useHomeRedirectWhenOut()

  return (
    <div className='SurveyPage fill flex column center'>
      {survey ? (
        <SurveyComponent survey={survey} />
      ) : (
        'loading...'
      )}
    </div>
  )
}
