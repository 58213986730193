export const routes = {
  HOME: '/',
  SIGN: '/sign',
  SURVEY: '/survey',
  RESET_PASSWORD: '/reset-password',
}

export const routeUtils = {
  SURVEY_ID: (id64: string) => `${routes.SURVEY}/${id64}`,
}
