import { useEffect, useMemo } from 'react'
import { PRNG } from 'some-utils/math'
import { useForceUpdate } from 'some-utils/npm/react'
import { deepPartialCopy } from 'some-utils/object'
import { ObservableObject } from 'some-utils/observables'

type UseLocalStorageCallback<T> = (props: Partial<T>, options?: Partial<{ delay: number }>) => void

export const getLocalStorage = <T>(id: string, initialValue: T) => {

  const seed = 34567 + [...id].map(x => x.charCodeAt(0)).reduce((x, y) => x + y)

  const retrieveData = () => {
    const str = localStorage.getItem(id)
    if (!str) {
      return {}
    }
    try {
      return JSON.parse(PRNG.decode(str, { seed }))
    }
    catch (e) {
      console.log(`Oops, invalid JSON.`)
      return {}
    }
  }

  const saveData = retrieveData()
  const data = { data: initialValue }
  deepPartialCopy(saveData, data)
  const obs = new ObservableObject(data)
  
  obs.onChange(() => {
    const str = PRNG.encode(JSON.stringify(obs.value), { seed })
    localStorage.setItem(id, str)
  })
  
  return obs
}

export const useLocalStorage = <T>(id: string, initialValue: T): [T, UseLocalStorageCallback<T>] => {

  const forceUpdate = useForceUpdate({ waitNextFrame: false })
  
  const [obs, update] = useMemo(() => {

    const obs = getLocalStorage(id, initialValue)

    const update: UseLocalStorageCallback<T> = (props, { delay = 0 } = {}) => {
      const call = () => obs.updateValue({ data: props as any })
      if (delay > 0) {
        window.setTimeout(call, delay)
      }
      else {
        call()
      }
    }

    obs.onChange(forceUpdate)
    
    return [obs, update]
  
  // It's ok:
  // "initialValue" value may change at each call and still being the same (an object).
  // By the way this is the same usage as "setState()" (no dependency on the initial value).
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, forceUpdate])

  return [obs.value.data as T, update]
}

export const useAutoBlurButton = ({
  selector = 'button, .button'
} = {}) => {
  useEffect(() => {
    const onPointerUp = () => {
      const element = document.activeElement as HTMLElement
      if (element.matches(selector)) {
        element.blur()
      }
    }
    window.addEventListener('pointerup', onPointerUp)
    return () => {
      window.removeEventListener('pointerup', onPointerUp)
    }
  }, [])
}