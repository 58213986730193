import { useState, useRef } from 'react'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import { useRefComplexEffects } from 'some-utils/npm/react'
import { ObservableNumber } from 'some-utils/observables'
import { handlePointer } from 'some-utils/dom'
import { svg } from 'view/svg'
import './DownloadButton.css'

const options = ['json', 'yaml', 'csv']

const currentOptionIndex = new ObservableNumber(0)

const ClickAwayListener = ({
  onClickAway,
  children,
}: {
  onClickAway?: () => void
  children?: JSX.Element
} = {}) => {
  const ref = useRefComplexEffects<HTMLDivElement>(function*(div) {

    yield handlePointer(document.documentElement, {
      onUp: event => {
        const out = div.contains(event.target as HTMLElement) === false
        if (out) {
          onClickAway?.()
        }
      },
    })

    const app = document.querySelector('.App') as HTMLElement
    app.style.pointerEvents = 'none'
    app.style.filter = 'blur(6px)'
    yield () => {
      app.style.pointerEvents = ''
      app.style.filter = ''
    }

  }, [])
  return (
    <div ref={ref}>
      {children}
    </div>
  )
}

export const DownloadButton = ({
  onClick = (type: string) => {},
} = {}) => {

  const [open, setOpen] = useState(false)
  const anchorRef = useRef<any>(null)
  const [selectedIndex, setSelectedIndex] = useState(currentOptionIndex.value)

  const handleMenuItemClick = (
    index: number,
  ) => {
    currentOptionIndex.setValue(index)
    setSelectedIndex(index)
    setOpen(false)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <div 
        className='ButtonSplit flex row'
      >
        <button
          className='expand padding-1em'
          onClick={() => onClick(options[selectedIndex])}>
          Download data ({options[selectedIndex]})
        </button>
        <button
          ref={anchorRef}
          onClick={handleToggle}
        >
          <svg.DropdownArrow />
        </button>
      </div>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              textTransform: 'uppercase',
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      disabled={index === selectedIndex}
                      selected={index === selectedIndex}
                      onClick={() => handleMenuItemClick(index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}
