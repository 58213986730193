import { DbItem, Survey } from 'project'
import { useState } from 'react'
import { safeClassName } from 'some-utils/npm/react'
import { fetchSurveys, userPost } from 'state/user'
import { defaultNameLocationState, NameLocation, NameLocationState } from '../NameLocation'

export const CreateSurvey = ({
  onCreate = (id64: string) => {},
}) => {

  const [state, setState] = useState<NameLocationState>(defaultNameLocationState)

  const {
    name,
    location,
    valid,
  } = state

  return (
    <div className='CreateSurvey ModalContent flex column center gutter-8'>

      <h1>
        Create New Survey
      </h1>

      <div className='space-1em' />

      <NameLocation 
        state={state}
        setState={setState}/>

      <div className='space-1em' />
      
      <button
        className={safeClassName('button', { disabled: valid === false })}
        onClick={async () => {
          if (valid) {
            const { ok, message, payload: survey } = await userPost<DbItem<Survey>>(
              '/api/survey/create', { name: name.trim(), location: location.trim() })
            if (ok) {
              await fetchSurveys()
              onCreate(survey.id64)
            }
            else {
              console.log(message)
            }
          }
        }}>
        Start survey
      </button>

    </div>
  )
}
