import { usePathname } from 'some-utils/npm/react/router'
import { routes } from 'project'
import { useUser } from 'state/user'
import { go } from 'state/routing'
import { svg } from './svg'
import './Header.css'

const Status = () => {
  const { authenticated } = useUser()
  const path = usePathname()
  return (
    <div className='button' onClick={go(routes.SIGN)}>
      {authenticated
        ? <button>{localStorage.getItem('email')}</button>
        : (path === '/sign'
          ? null
          : <button>sign in</button>
        )}
    </div>
  )
}

export const Header = () => {
  const isHome = usePathname() === routes.HOME
  return (
    <header
      className='Header flex row gutter-8'
    >
      <button 
        className='Logo button link flex row align-center' 
        onClick={go(routes.HOME)}
        style={{ padding: '4px' }}
      >
        {isHome === false && (
          <svg.Previous />
        )}
        Survey tool
      </button>
      <div className='expand' />
      <Status />
    </header>
  )
}
