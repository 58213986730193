import { useState } from 'react'
import { Dialog } from '@mui/material'
import { routeUtils } from 'project'
import { useUser } from 'state/user'
import { go } from 'state/routing'
import { downloadData } from 'utils/dowload-data'
import { RatioBar } from './RatioBar'
import { CloseSurvey, CreateSurvey, DeleteSurvey } from './modal'
import { DownloadButton } from './DownloadButton'
import { svg } from './svg'
import './SurveyList.css'

export const SurveyList = () => {

  const { surveys } = useUser()

  const [modal, setModal] = useState<null | { type: 'delete' | 'new' | 'close', id64?: string }>(null)
  const closeModal = () => setModal(null)

  return (
    <div className='SurveyList fill flex column'>

      <div>Available logs:</div>

      <div className='space-1em' />
      
      <div className='List flex column gutter-4'>
        {surveys.length > 0 ? (
          surveys.map((item, index) => (
            <div
              key={index}
              className={`SurveyListRow ${item.props.status}`}
            >

              <RatioBar survey={item}/>

              <div className='Border absolute-fill'/>

              <div 
                className='Inner button absolute-fill flex row gutter-4'
                onClick={event => {
                  const target = (event.target as HTMLElement)
                  const directClick = target.classList.contains('Inner')
                  const closeClick = target.classList.contains('Close')
                  const trashClick = target.classList.contains('Trash')
                  if (directClick) {
                    go(routeUtils.SURVEY_ID(item.id64))()
                  }
                  if (closeClick && item.props.status !== 'close') {
                    setModal({ type: 'close', id64: item.id64 })
                  }
                  if (trashClick) {
                    setModal({ type: 'delete', id64: item.id64 })
                  }
                }}
              >
                <div className='Name expand flex row align-center gutter-4 overflow-hidden'>
                  <div className='space-4' />
                  <span className='NameSpan overflow-hidden'>{item.props.name}</span>
                  <span>({item.props.positiveVotesCount + item.props.negativeVotesCount})</span>
                </div>

                <div className='Location expand flex row align-center gutter-4 overflow-hidden'>
                  <svg.Location />
                  <span className='NameSpan overflow-hidden'>{item.props.location}</span>
                </div>

                <div className='Option Live button flex row center'>
                  {item.props.status === 'live' ? <svg.ArrowCycle /> : null}
                </div>
                <div className='Option Close button flex row center'>
                  {item.props.status === 'close' ? <svg.Lock /> : <svg.Unlock />}
                </div>
                <div className='Option Trash button flex row center'>
                  <svg.Trash />
                </div>
              </div>

            </div>
          ))
        ) : (
          <p className='dim text-center' style={{ fontStyle: 'italic' }}>
            No surveys, for the moment?
          </p>
        )}
      </div>
      
      <button
        className='PlusButton button blank'
        onClick={() => setModal({ type: 'new' })}
      >
        <svg.PlusButton className='fill' />
      </button>

      <DownloadButton onClick={type => downloadData(surveys, type)} />

      <div className='space-32' />

      <Dialog
        open={modal?.type === 'delete'}
        onClose={closeModal}
      >
        <DeleteSurvey 
          onClose={closeModal}
          survey={surveys.find(s => s.id64 === modal?.id64)} />
      </Dialog>

      <Dialog
        open={modal?.type === 'close'}
        onClose={closeModal}
      >
        <CloseSurvey
          onClose={closeModal}
          survey={surveys.find(s => s.id64 === modal?.id64)!} />
      </Dialog>

      <Dialog
        open={modal?.type === 'new'}
        onClose={closeModal}
      >
        <CreateSurvey
          onCreate={id64 => {
            go(routeUtils.SURVEY_ID(id64))()
          }}
        />
      </Dialog>

    </div>
  )
}
