import { useObservable } from 'some-utils/npm/react'
import { Observable } from 'some-utils/observables'

const computeResponsiveMode = (): string => {
  const { innerWidth: width, innerHeight: height } = window
  const aspect = width / height
  // const [min, max] = aspect > 1 ? [width, height] : [height, width]
  
  const set = new Set<string>()

  set.add(aspect > 1 ? 'landscape' :  'portrait')

  if (aspect > 1) {
    // LANDSCAPE
    if (width > 1050) {
      set.add('desktop')
    }
    else if (width > 800) {
      set.add('tablet')
    }
    else {
      set.add('mobile')
    }
  }
  
  else {
    // PORTRAIT
    if (width > 1050) {
      set.add('desktop')
    }
    else if (width > 700) {
      set.add('tablet')
    }
    else {
      set.add('mobile')
    }
  }

  return [...set].join(',')
}

export const responsive = new Observable<string>(computeResponsiveMode())

window.addEventListener('resize', () => responsive.setValue(computeResponsiveMode()))


let initialized = false
const init = () => {
  if (initialized === false) {
    responsive.withValue((value, { valueOld }) => {
      for (const key of valueOld.split(',')) {
        document.documentElement.classList.remove(key)
      }
      for (const key of value.split(',')) {
        document.documentElement.classList.add(key)
      }
    })
    initialized = true
  }
}

export const useResponsive = () => {
  init()
  return useObservable(responsive)
}
