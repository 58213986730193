import { DbItem, Survey } from 'project'
import { updateSurvey } from 'state/user'
import { svg } from '../svg'

type Props = {
  survey: DbItem<Survey>
  onClose: () => void
}

export const CloseSurvey = ({
  survey,
  onClose,
}: Props) => {
  return (
    <div className='CloseSurvey ModalContent flex column gutter-16'>

      <h1 className='flex row center pre'>
        <svg.Trash />
        <span className='space-8' />
        Close the survey
      </h1>
      
      <div className='space-1em' />

      <p>The survey will not be editable anymore.</p>
            
      <div className='space-1em' />

      <div className='flex row stretch gutter-16 expand-content'>
        <button
          onClick={onClose}
        >
          Cancel
        </button>
        <button
          onClick={async () => {
            survey.props.status = 'close'
            await updateSurvey(survey)
            onClose()
          }}
        >
          Confirm
        </button>
      </div>
    </div>
  )
}
