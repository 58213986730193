import { Snackbar, Dialog } from '@mui/material'
import { useEffect, useState } from 'react'
import { setPathname } from 'some-utils/router'
import { EmailPassword } from 'view/EmailPassword'
import { routes } from 'project'
import { logout, sign, useUser } from 'state/user'
import { go } from 'state/routing'
import { fetchText, getLocalStorage, postJson, useLocalStorage } from 'utils'
import { safeClassName } from 'some-utils/npm/react'
import './Sign.css'

const SignInOrUp = () => {

  type Mode = 'new-account' | 'sign-in'
  type Modal = null | 'reset-password-prompt' | 'please-wait'

  const [mode, setMode] = useState<Mode>('new-account')
  const [modal, setModal] = useState<Modal>(null)
  const [snack, setSnack] = useState('')
  const [invalidPassword, setInvalidPassword] = useState(false)

  const [{ email }] = useLocalStorage('sign', { email: '' })

  useEffect(() => {
    const { email } = getLocalStorage(`sign`, { email: '' }).value.data
    fetchText(`/api/user/exists/${email}`).then(value => {
      const exists = /true|1/.test(value)
      setMode(exists ? 'sign-in' : 'new-account')
    })
  }, [])

  const submitLabel = {
    'new-account': 'Create an account',
    'sign-in': 'Sign in',
  }[mode]

  return (
    <>
      <h2>Please, login</h2>
      <p className='small'>Create a new account or log into an existing one.</p>
      <div className='space-1em' />
      <EmailPassword
        submitLabel={submitLabel}
        invalidPassword={invalidPassword}
        onEmailChange={async (email, valid) => {
          if (valid) {
            const exists = /true|1/.test(await fetchText(`/api/user/exists/${email}`))
            setMode(exists ? 'sign-in' : 'new-account')
          }
          else {
            setMode('new-account')            
          }
        }}
        onSubmit={async (email, password) => {
          const ok = await sign(email, password)
          if (ok) {
            setPathname('/')
          }
          else {
            setSnack('Invalid password.')
            setInvalidPassword(true)
            setTimeout(() => setInvalidPassword(false), 1000)
          }
        }}
      />
      <div className='space-1em' />
      <p className='small'>
        <button 
          className={safeClassName('button link', { disabled: mode === 'new-account' })}
          onClick={() => setModal('reset-password-prompt')}>Forgotten password?</button>
      </p>

      <Snackbar 
        open={!!snack}
        message={snack}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={() => setSnack('')}
      />

      <Dialog
        open={modal === 'reset-password-prompt'}
        onClose={() => setModal(null)}>
          <div className='ModalContent flex column center gutter-16'>

            <h1>
              Reset password
            </h1>
            
            <p>
              An email will be sent to "{email}".
            </p>

            <div className='space-1em' />

            <div className='expand flex row expand-content gutter-16'>
              <button onClick={() => setModal(null)}>
                Cancel
              </button>
              <button onClick={async () => {
                setModal('please-wait')
                const { ok, message } = await postJson('/api/user/reset-password', { email })
                setSnack(ok ? `An email has been sent to "${email}".` : message)
                setModal(null)
              }}>
                Send
              </button>
            </div>

          </div>
      </Dialog>

      <Dialog
        open={modal === 'please-wait'}
        onClose={() => setModal(null)}>
          <div className='ModalContent'>
            Please wait a moment.
          </div>
      </Dialog>
    </>
  )
}

const Unsign = () => {
  const { email } = useUser()
  return (
    <>
      <p className='text-center'>You're already connected. 👍</p>
      <p className='text-center small'>{email}</p>
      <div className='space-1em' />
      <button className='button' onClick={logout}>Log out</button>
    </>
  )
}

export const Sign = () => {

  const { signStatus } = useUser()

  return (
    <div className='Sign flex column center fill'>

      <h1 className='button' onClick={go(routes.HOME)}>Survey tool</h1>

      <div className='space-4em'/>

      <div className='Box flex column gutter-8'>
        {signStatus === 'fetching' ? (
          <div>loading</div>
        ) : signStatus === 'out' ? (
          <SignInOrUp />
        ) : (
          <Unsign />
        )}
      </div>
    </div>
  )
}
