import { DbItem, Survey } from 'project'
import { useState } from 'react'
import { fetchSurveys, userPost } from 'state/user'
import { svg } from 'view/svg'

type Props = {
  survey?: DbItem<Survey>
  onClose: () => void
  onDelete?: () => void
}
export const DeleteSurvey = ({
  survey,
  onClose,
  onDelete,
}: Props) => {

  // Make a backup of the survey (to display props even if the survey has been deleted).
  const [surveyCopy] = useState(survey)

  if (!surveyCopy) {
    return null
  }

  // This is tricky. And based on the fact that once the survey has been deleted it will
  // appear here as 'undefined' and the copy still have, of course, a value.
  // When both the values do not match anymore, it means that the survey does not exist anymore. 
  const deleted = surveyCopy.id64 !== survey?.id64

  const {
    id64,
    props: { name, location },
  } = surveyCopy

  return (
    <div className='DeleteSurvey ModalContent flex column gutter-16'>

      <h1 className='flex row center pre'>
        <svg.Trash />
        <span className='space-8' />
        Delete Survey
      </h1>
      
      <div className='space-1em' />
      
      <p style={{ lineHeight: '1.4em' }}>
        {deleted === false ? (
          <>
            Delete survey "{name}" @ "{location}"
            <br />
            <span className='dim'> (id: {id64})</span>
            <br />
            ⚠️ This is not reversible!
          </>          
        ) : (
          <>
            Survey {id64} has been deleted.
          </>
        )}
      </p>
      
      <div className='space-1em' />

      <div className='flex row stretch gutter-16'>
        {deleted === false ? (
          <>
            <button className='expand' onClick={onClose}>
              Cancel
            </button>
            <button className='expand' onClick={async () => {
              const { ok } = await userPost('/api/survey/delete', { id64 })
              if (ok) {
                onDelete?.()
                await fetchSurveys()
              }
            }}>
              Confirm
            </button>
          </>
        ) : (
          <button className='expand' onClick={onClose}>Ok</button>
        )}
      </div>
    </div>
  )
}
