
type State = {
  name: string
  location: string
  nameValid: boolean
  locationValid: boolean
  valid: boolean
}

type Props = {
  state: State
  setState: (state: State) => void
}


const defaultState: State = {
  name: '',
  location: '',
  nameValid: false,
  locationValid: false,
  valid: false,
}

const NameLocation = ({
  state,
  setState,
}: Props) => {

  const update = (partialState: Partial<State>) => {
    const { name, location } = { ...state, ...partialState }
    const nameValid = name.length >= 2
    const locationValid = location.length >= 2
    const valid = nameValid && locationValid
    setState({
      name,
      location,
      nameValid,
      locationValid,
      valid,
    })
  }

  return (
    <>
      <input
        className='border-bottom'
        type='text'
        name='survey-name'
        placeholder='name'
        value={state.name}
        onInput={e => update({ name: (e.target as HTMLInputElement).value })} />
      <input
        className='border-bottom'
        type='text'
        name='survey-location'
        placeholder='location'
        value={state.location}
        onInput={e => update({ location: (e.target as HTMLInputElement).value })} />
    </>
  )
}

export type {
  State as NameLocationState,
  Props as NameLocationProps,
}

export {
  NameLocation,
  defaultState as defaultNameLocationState,
}
